/* NAV */
/* NAV */

.nav-logo {
  background: url("../assets/images/logos/taj-logo_w-outline.png") no-repeat
    center center;
  background-size: contain;
  width: 75px;
  height: 75px;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* HEADER */
/* HEADER */

.header-img {
  background: url("./images/logos/tajLogo_w-o-text_washed.png") no-repeat center
    center fixed;
  background-size: cover;
  max-height: 650px;
}

/* ABOUT */
/* ABOUT */

.taj-store-first {
  background: url("../assets/images/salon-store-view/salonOutside2.jpeg") center
    top;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 6px 6px 0 #000000a4;
  height: 700px;
  border-bottom: solid white 3px;
  border-bottom-right-radius: 1%;
  border-bottom-left-radius: 1%;
}

.taj-store-second {
  background: url("../assets/images/salon-store-view/salonInside2.jpeg") center
    top;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 6px 6px 0 #000000a4;
  height: 600px;
  border-bottom: solid white 3px;
  border-bottom-right-radius: 1%;
  border-bottom-left-radius: 1%;
}

.homage-to-juli {
  background: url("../assets/images/homageToJuli.jpg") center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #2b4141;
  box-shadow: 0 6px 6px 0 #000000a4;
  min-height: 340px;
  min-width: 260px;
  border: outset #efefef 1px;
  border-radius: 2%;
  margin: auto;
}

/* MEDIA QUERIES */

@media screen and (max-width: 450px) {
  .header-img {
    background: url("./images/logos/tajLogo_w-o-text_washed.png") no-repeat
      center center;
    background-size: cover;
    max-height: 500px;
  }

  .taj-store-first {
    background: url("../assets/images/salon-store-view/salonOutside2.jpeg") left
      top;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom: solid white 3px;
    border-bottom-right-radius: 1%;
    border-bottom-left-radius: 1%;
    box-shadow: 0 6px 6px 0 #000000a4;
    height: 500px;
  }

  .taj-store-second {
    background: url("../assets/images/salon-store-view/salonInside2.jpeg")
      center top;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom: solid white 3px;
    border-bottom-right-radius: 1%;
    border-bottom-left-radius: 1%;
    height: 245px;
  }
}
