/* NOTE */
/* see individual components for inline styling details */

/* FONT IMPORT */
@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@600&display=swap");

/* GENERAL */
/* GENERAL */
/* GENERAL */

* {
  font-family: "Josefin Slab", serif !important;
}

#root {
  background-color: black;
}

html,
body {
  width: 100%;
  height: 100%;
}

h1 {
  margin-top: 0;
  text-align: center;
  /* color: #ddede2; */
}

li {
  text-align: start;
}

a:hover {
  color: #d5bece;
}

/* UTILITIES */
/* UTILITIES */
/* UTILITIES */

.header-title {
  font-size: 64px;
}

.page-title {
  text-shadow: 0px 1px 4px rgba(212, 232, 210, 0.329);
  color: white;
}

.background-blur {
  backdrop-filter: blur(9px);
  width: 100%;
}

/* CONTACT MODAL */
/* CONTACT MODAL */
/* CONTACT MODAL */

.iframe {
  border: 1px solid black;
  height: 200px;
}

.contact-container {
  margin: 0 auto;
}

.contact-store-info {
  text-align: center;
  margin: -5px;
}

.contact-store-info p {
  margin-top: -5px;
  font-size: small;
}

.contact-store-info h3 {
  text-shadow: 0px 1px 4px rgba(212, 232, 210, 0.329);
  color: #2e5b5b;
}

.contact-button {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.location-card {
  margin: 0 auto;
}

/* HEADER */
/* HEADER */
/* HEADER */

.header {
  width: 100%;
  height: 650px;
  border-bottom: ridge 5px white;
  border-radius: 0% 0% 50% 40%;
  padding-top: 100px;
}

.header-background {
  position: relative;
}

.header-text {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
  max-width: 100%;
  padding-top: 10px;
  text-shadow: 0px 1px 4px rgba(0, 0, 0);
  color: white;
}

/* ABOUT */
/* ABOUT */
/* ABOUT */

.about-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9%;
  /* padding-bottom: 0px; */
  text-shadow: 3px 3px 1px rgba(0, 0, 0);
  box-shadow: 0 6px 6px 0 #000000a4;
}

.about-services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  color: #1f271b;
  background-color: #cbc4b9;
  /* box-shadow: 0 6px 6px 0 #000000a4; */
  font-size: large;
  /* border: solid white 3px; */
  border-radius: 0% 0% 20% 40%;
}

.about-split {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  color: #1f271b;
  background-color: #cbc4b9;
  box-shadow: 0 6px 6px 0 #000000a4;
  border: solid white 3px;
  border-radius: 20% 10% 50% 40%;
}

.about-card {
  padding-bottom: 0px;
  padding-top: 10px;
  margin-bottom: -3px !important;
  color: #1f271b;
}

/* SERVICES */
/* SERVICES */
/* SERVICES */

/* MAIN CAROUSEL */

.carousel-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  background: linear-gradient(180deg, #000000 40%, #cbc4b9 100%);
}

.carousel-styled {
  max-width: 700px;
  text-align: center;
}

.carousel-center img {
  border: 3px solid white;
  border-radius: 3%;
}

/* SUB-CAROUSEL */
/* LOOK FOR *NOTE* OF ADJUSTING FOR MEDIA QUERY */

.accordion-split {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  background: linear-gradient(180deg, #cbc4b9 0%, #000000 100%);
}

.accordion-list {
  display: flex;
  flex-direction: column;
  padding: 3%;
  text-align: center;
  align-items: center;
  margin: auto;
  color: white;
  text-shadow: 0px 1px 4px black;
}

.accordion-slot {
  min-height: 100%;
  width: 40%;
}

.sub-carousel-styled {
  margin: 2% auto;
  height: 500px;
  width: 500px;
  background-color: #00000050;
  border: 2px ridge #cbc4b9;
  border-radius: 3%;
  /* margin-right: 40%; */
}

/* *NOTE* NEEDS MEDIA QUERY */
.sub-carousel-img-container {
  position: relative;
}

.sub-carousel-styled img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center top;
}

/* *NOTE* NEEDS MEDIA QUERY */
.sub-carousel-caption {
  /* font-size: small; */
  /* margin-bottom: 100px; */
  width: 100%;
  position: absolute;
  bottom: 24px;
  z-index: 5;
  /* padding-bottom: 0px; */
  padding-top: 5px;
  padding-bottom: 1px;
  /* margin-bottom: 10px; */
  /* left: 10%; */
  /* right: 10%; */
  text-align: center;
  color: white;
  border-top: 0.1px solid white;
  border-bottom: 0.1px solid white;
  /* background-color: #00000039; */
}

.sub-carousel-caption h3 {
  margin-bottom: 0px;
}

/* sub-carousel indicator icon placement */
.css-1m9128y {
  position: absolute !important;
  bottom: 0 !important;
  margin-top: 0 !important;
  backdrop-filter: blur(9px) !important;
}

/* SERVICECONTENT CARD */

.service-card {
  text-align: center;
  width: 27%;
  margin: 1%;
  max-height: fit-content;
  align-self: start;
}

.service-content-card {
  margin: 0 auto;
}

.service-content-card p {
  font-size: larger;
  margin-bottom: -10px;
}

.accordion-sizing {
  /* margin: 0px 0% 5px; */
  border: none;
  /* margin-top: -2px; */
}

/* .accordion-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-around;
} */

/* MEET THE TEAM */
/* MEET THE TEAM */
/* MEET THE TEAM */

.meet-team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: auto;
  /* padding: 2%; */
  background: linear-gradient(
    180deg,
    #000000 0%,
    #a59174cc 35%,
    #a59174e6 50%,
    #a59174cc 65%,
    #000000 100%
  );
}

/* .meet-team-container-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: auto;
  margin-top: 5%;
} */

.group-card {
  width: 550px;
  margin-bottom: 50px;
  height: 94%;
  text-align: center;
}

.team-member-card {
  width: 450px;
  /* min-height: 600px; */
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;
}

/* MEDIA QUERIES */
/* MEDIA QUERIES */
/* MEDIA QUERIES */

@media screen and (max-width: 1025px) {
  /* SERVICES */
  /* SERVICES */
  /* SERVICES */

  .accordion-list {
    text-align: center;
    align-items: center;
    margin-right: auto;
  }

  .accordion-slot {
    /* min-height: 100%; */
    width: 60%;
  }

  /* .service-card {
    min-width: 46%;
  }

  .service-content-card p {
    font-size: larger;
  } */

  /* MEET THE TEAM */
  /* MEET THE TEAM */
  /* MEET THE TEAM */
}

@media screen and (max-width: 450px) {
  /* GENERAL */
  /* GENERAL */
  /* GENERAL */

  /* UTILITIES */
  /* UTILITIES */
  /* UTILITIES */
  .header-title {
    font-size: 58px;
  }

  /* SERVICES */
  /* SERVICES */
  /* SERVICES */
  .carousel-styled {
    width: 95%;
    text-align: center;
  }

  /* .carousel-center {
    min-height: auto;
  } */

  .carousel-caption h3 {
    font-size: small;
    margin-bottom: -25px;
  }

  .carousel-caption p {
    display: none;
  }

  /* sub-carousel */
  .sub-carousel-styled {
    /* margin: 2% auto; */
    height: 340px;
    width: 340px;
    /* background-color: black; */
    /* border: 2px ridge #cbc4b9; */
    /* border-radius: 3%; */
    /* margin-right: 40%; */
  }

  .accordion-list {
    /* display: flex; */
    /* flex-direction: column; */
    padding: 1%;
    text-align: right;
    align-items: flex-end;
    /* margin: auto; */
    /* color: white; */
    /* text-shadow: 0px 1px 4px black; */
  }

  .accordion-slot {
    /* min-height: 100%; */
    width: 80%;
  }

  .service-card {
    min-width: 90%;
  }

  .service-content-card p {
    font-size: larger;
  }

  /* MEET THE TEAM */
  /* MEET THE TEAM */
  /* MEET THE TEAM */
  .group-card {
    width: 370px;
    height: auto;
  }

  .team-member-card {
    width: 340px;
    border: solid black 2px;
    margin-bottom: 50px;
    min-height: 600px;
  }

  .about-split {
    border-radius: 20% 10% 8% 10%;
  }
}
